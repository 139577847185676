<script>
  import { project, postData } from '$lib/services/store'
  import Icons from '$lib/main/components/Icons.svelte'

  export let path = "/"
  export let icon = null
</script>

{#if path != '/'}
  <div class="block block-breadcrumbs style-a">
    <div class="container">
      <div class="row">
        <ul>
          {#if path.startsWith('/admin')}
            {#each path.split('/') as c, i}
              {#if i == 0 && path.split('/').length < 3}
                <li class="home"><a href="/admin"><span>
                  <Icons name="home" class="icon" />
                </span></a></li>
              {:else if i == 0}
                <li><a href="/admin"><span>
                  <Icons name="home" class="icon" />
                </span></a></li>
              {:else if i == 1}
                <!-- TODO: pass to remove admin from appearing twice in breadcrumbs -->
              {:else if i == path.split('/').length-1}
                <li class="current"><span>{c.replaceAll('-', ' ')}</span></li>
              {:else}
                <li class="parent"><a href={path.split(c)[0] + c}><span>{c.replaceAll('-', ' ')}</span></a></li>
              {/if}
            {/each}
          {:else}
            {#each path.split('/') as c, i}
              {#if i == 0}
                <li><a href="/"><span>
                  {#if icon || $project.name == null}
                    <Icons name="home" class="icon" />
                  {:else}
                    {$project.name} <!-- TODO: replace with 'main-keyword' or another value in site settings/SEO settings -->
                  {/if}
                </span></a></li>
              {:else if i == path.split('/').length-1}
                <li class="current"><span>{ $postData?.seo?.breadcrumb ? $postData?.seo?.breadcrumb : c.replaceAll('-', ' ')}</span></li>
              {:else}
                <li class="parent"><a href={path.split(c)[0] + c}><span>{c.replaceAll('-', ' ')}</span></a></li>
              {/if}
            {/each}
          {/if}
        </ul>
      </div>
    </div>
  </div>
{/if}