<script>
  import { project } from "$lib/services/store";
  import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import ImageUpload from "$lib/admin/components/ImageUpload.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Content from '$lib/admin/components/blocks/blocks/Content.svelte';
	import Button from "$lib/main/components/Button.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';

	// props.imageBlockType: select - Choose the type of image component this will be
  //  options: basic, content, side and carousel
  // props.images: array - array of image objects
  // props.images[i].url: input - Full URL to the image used in the component
  // props.images[i].alt: input - for the `alt` tag for images
  // props.images[i].caption: input - for the caption for images (optional)
  // props.images[i].border: bool - give image a border
  // props.images[i].float: select - if imageBlockType is content, then you can choose where to float the image
  //   options: left, no-float and right

  export let props = null;
  export let adminView = false;

  const dispatch = createEventDispatcher();

  let selectedImage = 0;

  $: {
    if (props.images === undefined) {
      props.images = [{}];
    }
  }
</script>

{#if adminView}
  <Group addClass="form-item" colspan="4-8">
    <Input
      label="Image block type"
      id="imageBlockType"
      type="select"
      bind:value={props.imageBlockType}
      on:change={() => dispatch("keyup")}>
      <option value="single">Image - Single</option>
      <option value="content">Image - In-content</option>
      <option value="multi">Image - Side-by-side</option>
      <option value="carousel">Image - Carousel</option>
    </Input>
  </Group>
  {#if props.images.length}
    {#each props.images as image, idx}
      {#if
        !(((props.imageBlockType === 'single') || (props.imageBlockType === 'content'))
        && (idx > 0))
        && !(((props.imageBlockType === 'multi') || (props.imageBlockType === 'carousel')) && (idx != selectedImage))
        }
        <Group colspan="2" addClass="form-item image-editor">
          <ImageUpload bind:imageUrl={image.url} on:change={() => dispatch("keyup")} />
          {#if image.url}
            <Group colspan="1" addClass="small-input">
              <Group>
                <Input
                  label="Alt text"
                  placeholder="Alternative text"
                  id="alt-text"
                  bind:value={image.alt}
                  on:keyup
                />
              </Group>
              <Group>
                <Input
                  label="Image caption"
                  placeholder="Image caption"
                  id="caption"
                  bind:value={image.caption}
                  on:keyup
                />
              </Group>
              <Group colspan="1-11">
                <Input
                  label="Image border"
                  type="checkbox"
                  bind:checked={image.border}
                  on:change={() => dispatch("keyup")} />
              </Group>
              {#if props.imageBlockType === 'content'}
                <Group>
                  <Input
                    label="Image float"
                    id="imageFloat"
                    type="select"
                    bind:value={props.images[0].float}
                    on:change={() => dispatch("keyup")}>
                    <option value="no-float">None</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </Input>
                </Group>
              {/if}
            </Group>
          {/if}
        </Group>
        {#if props.imageBlockType === 'content'}
          <Content {adminView} {props} on:keyup />
        {/if}
        {#if (props.imageBlockType === 'multi') || (props.imageBlockType === 'carousel')}
          <Group colspan="1-auto" addClass="form-item narrow">
            <Group colspan="1-auto-auto" addClass="narrow">
              <div class="multi-meta">Showing image { selectedImage + 1 } of { props.images.length } images</div>
                <Button
                  on:click={() => {
                    if (selectedImage > 0) selectedImage -= 1;
                    else selectedImage = props.images.length - 1;
                  }}>‹</Button>
                <Button
                  on:click={() => {
                    selectedImage += 1;
                    if (selectedImage >= props.images.length) selectedImage = 0;
                  }}>›</Button>
            </Group>
            <Group addClass="inline narrow">
              {#if props.images.length > 1}
              <Button
                addClass="secondary"
                title="Remove this image"
                onlyIcon
                icon="trash-2"
                on:click={() => {
                  props.images = props.images.filter((_, i) => i !== idx);
                  if(selectedImage > 0) { selectedImage -= 1 }
                  dispatch("keyup");
                }}>Remove</Button
              >
              {/if}
              {#if !props.images.length || (props.imageBlockType === 'multi') || (props.imageBlockType === 'carousel')}
                <Button
                  addClass="primary"
                  title="Add an image"
                  onlyIcon
                  icon="plus-square"
                  on:click={() => {
                    props.images.push({});
                    dispatch("keyup");
                  }}>Add Image</Button
                >
              {/if}
            </Group>
          </Group>
        {/if}
      {/if}
    {/each}
  {/if}
{:else}
  <Block
    blockClass={"block-image" + (props.imageBlockType ? " " + props.imageBlockType : "")}
    gridColumn={"g-col-12 g-start-1 g-col-md-10 g-start-md-2"}
    {props}>
    {#if props.images?.length}
      {#if (props.imageBlockType === 'single') || !props.imageBlockType}
        {#if props.images[0].border}
          <div class="image-holder border">
            <img
              data-src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
              alt={props.images[0].alt}
              class={"in-content lazyload"}
              loading="lazy"
              src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
            />
            {#if props.images[0].caption}
              <p class="img-caption">{@html props.images[0].caption}</p>
            {/if}
          </div>
        {:else}
          <img
            data-src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
            alt={props.images[0].alt}
            class={"in-content lazyload"}
            loading="lazy"
            src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
          />
          {#if props.images[0].caption}
            <p class="img-caption">{@html props.images[0].caption}</p>
          {/if}
        {/if}
      {:else if props.imageBlockType === 'content'}
        <div class={ "image-holder" + (props.images[0].float ? " " + props.images[0].float : "")}>
          <img
            data-src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
            alt={props.images[0].alt}
            class={"in-content lazyload"}
            class:border={props.images[0].border}
            loading="lazy"
            src={$project.data?.imageFolders?.media + props.images[0].url + "?w=600&q=85&auto=format"}
          />
          {#if props.images[0].caption}
            <p class="img-caption">{@html props.images[0].caption}</p>
          {/if}
        </div>
        {#if props.content}
          {@html props.content}
        {/if}
      {:else if (props.imageBlockType === 'multi') || (props.imageBlockType === 'carousel')}
          <div class={props.imageBlockType}>
            {#each props.images as image, i}
              <div class="image-holder">
                <img
                  data-src={$project.data?.imageFolders?.media + image.url + "?w=600&q=85&auto=format"}
                  alt={image.alt}
                  class={"in-content lazyload"}
                  loading="lazy"
                  class:border={image.border}
                  src={$project.data?.imageFolders?.media + image.url + "?w=600&q=85&auto=format"}
                />
                {#if image.caption}
                  <p class="img-caption">{@html image.caption}</p>
                {/if}
              </div>
            {/each}
          </div>
        {/if}
      {/if}
  </Block>
{/if}