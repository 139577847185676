// blocks
// Regular blocks being used in content creation
import Bonus from "$lib/admin/components/blocks/blocks/Bonus.svelte"
import ButtonLink from "$lib/admin/components/blocks/blocks/ButtonLink.svelte"
import Cards from "$lib/admin/components/blocks/blocks/Cards.svelte"
import Content from "$lib/admin/components/blocks/blocks/Content.svelte"
import FAQ from "$lib/admin/components/blocks/blocks/FAQ.svelte"
import Image from "$lib/admin/components/blocks/blocks/Image.svelte"
import FocusPanel from "$lib/admin/components/blocks/blocks/FocusPanel.svelte"
import Links from "$lib/admin/components/blocks/blocks/Links.svelte"
import List from "$lib/admin/components/blocks/blocks/List.svelte"
import PostList from "$lib/admin/components/blocks/blocks/PostList.svelte"
import Separator from "$lib/admin/components/blocks/blocks/Separator.svelte"
import Table from "$lib/admin/components/blocks/blocks/Table.svelte"
import TableFacts from "$lib/admin/components/blocks/blocks/TableFacts.svelte"
import TabbedTable from "$lib/admin/components/blocks/blocks/TabbedTable.svelte"
import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte"
import Video from "$lib/admin/components/blocks/blocks/Video.svelte"

// meta
// Mostly helper blocks that are used to accompany user-generated components
import ArticleAd from "$lib/admin/components/blocks/helpers/ArticleAd.svelte"
import LastUpdated from "$lib/admin/components/blocks/helpers/LastUpdated.svelte"
import Placeholder from "$lib/admin/components/blocks/helpers/Placeholder.svelte"

// helpers
// Blocks that are not used directly, but rather indirectly in other blocks
import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte"
import Rating from "$lib/admin/components/blocks/helpers/Rating.svelte"
import Search from "$lib/admin/components/blocks/helpers/Search.svelte"
import SectionStyling from "$lib/admin/components/blocks/helpers/SectionStyling.svelte"
import TableOfContent from "$lib/admin/components/blocks/helpers/TableOfContent.svelte"
import Archive from "$lib/admin/components/blocks/helpers/Archive.svelte"

// sections
// Blocks that are considered separate from content or are whole sections of content
import Intro from "$lib/admin/components/blocks/sections/Intro.svelte"
import IntroLinks from "$lib/admin/components/blocks/sections/IntroLinks.svelte"
import IntroReview from "$lib/admin/components/blocks/sections/IntroReview.svelte"
import WriterMeta from "$lib/admin/components/blocks/sections/WriterMeta.svelte"
import FixedData from "$lib/admin/components/blocks/sections/FixedData.svelte"
import MatchCenter from "$lib/admin/components/blocks/testing/MatchCenter.svelte"
import BettingTips from "$lib/admin/components/blocks/testing/BettingTips.svelte"

// testing
// Components that are close to ready and just being tested

// wip
// Components that are just being started, ideas and generally stuff that are quite new
import Banner from "$lib/admin/components/blocks/testing/Banner.svelte"
import CricketBettingTipsSingle from "$lib/admin/components/blocks/testing/CricketBettingTipsSingle.svelte"
import OddsList from "$lib/admin/components/blocks/testing/OddsList.svelte"
import PredictionsList from "$lib/admin/components/blocks/testing/PredictionsList.svelte"
import StepByStep from "$lib/admin/components/blocks/testing/StepByStep.svelte"

////
// WORK IN PROGRESS
////

// features
/*
import ContactForm from "$lib/admin/components/blocks/features/ContactForm.svelte"
import ReviewHero from "$lib/admin/components/blocks/features/ReviewHero.svelte"
 */

// match
/*
import About from "$lib/admin/components/blocks/features/match/About.svelte"
import Dream11 from "$lib/admin/components/blocks/features/match/Dream11.svelte"
import Header from "$lib/admin/components/blocks/features/match/Header.svelte"
import Info from "$lib/admin/components/blocks/features/match/Info.svelte"
import Livescore from "$lib/admin/components/blocks/features/match/Livescore.svelte"
import Odds from "$lib/admin/components/blocks/features/match/Odds.svelte"
import Teampreviews from "$lib/admin/components/blocks/features/match/Teampreviews.svelte"
*/

// tournament
/*
import IntroTournament from "$lib/admin/components/blocks/features/tournament/IntroTournament.svelte"
import LatestPredictions from "$lib/admin/components/blocks/features/tournament/LatestPredictions.svelte"
import PointsTable from "$lib/admin/components/blocks/features/tournament/PointsTable.svelte"
*/

// sections
/*
import ArticleEventHeader from "$lib/admin/components/blocks/sections/ArticleEventHeader.svelte"
import ArticleMeta from "$lib/admin/components/blocks/sections/ArticleMeta.svelte"
import Guide from "$lib/admin/components/blocks/sections/Guide.svelte"
import IntroArticle from "$lib/admin/components/blocks/sections/IntroArticle.svelte"
import IntroLinks from "$lib/admin/components/blocks/sections/IntroLinks.svelte"
import SideBySide from "$lib/admin/components/blocks/sections/SideBySide.svelte"
import ToplistSection from "$lib/admin/components/blocks/sections/ToplistSection.svelte"
 */

// testing
/*
import Newsletter from "$lib/admin/components/blocks/testing/Newsletter.svelte"
import ReviewTemplate from "$lib/admin/components/blocks/testing/ReviewTemplate.svelte"
 */

const imports = {
  'Bonus': {cls: Bonus, folder: "Blocks"},
  'ButtonLink': {cls: ButtonLink, folder: "Blocks"},
  'Cards': {cls: Cards, folder: "Blocks"},
  'Content': {cls: Content, folder: "Blocks", extraOptions: ["Add to TOC"]},
  'FAQ': {cls: FAQ, folder: "Blocks", extraOptions: ["Heading", "Section Styling", "Add to TOC"]},
  'Image': {cls: Image, folder: "Blocks", extraOptions: ["Add to TOC"]},
  'FocusPanel': {cls: FocusPanel, folder: "Blocks", extraOptions: ["Add to TOC"]},
  'Links': {cls: Links, folder: "Blocks"},
  'List': {cls: List, folder: "Blocks", extraOptions: ["Heading", "Add to TOC"]},
  'PostList': {
    cls: PostList,
    folder: "Blocks",
    extraOptions: [
      "Heading",
      "Read More Link",
      "Add Pagination",
      "Section Styling",
      "Add to TOC"
    ]
  },
  'Separator': {cls: Separator, folder: "Blocks"},
  'Table': {cls: Table, folder: "Blocks", extraOptions: ["Heading", "Add to TOC"]},
  'TableFacts': {cls: TableFacts, folder: "Blocks", extraOptions: ["Add to TOC"]},
  'TabbedTable': {cls: TabbedTable, folder: "Blocks"},
  'Toplist': {
    cls: Toplist,
    folder: "Blocks",
    extraOptions: [
      "Heading",
      "Toplist Navigation Bar",
      "Toplist Load More",
      "Toplist Link Button",
      "Toplist Button",
      "Add to TOC",
      "Toplist Fixed Content"
    ]
  },
  'Video': {cls: Video, folder: "Blocks"},

  'ArticleAd': {cls: ArticleAd, folder: "Meta"},
  'LastUpdated': {cls: LastUpdated, folder: "Meta"},
  'Placeholder': {cls: Placeholder, folder: "Meta"},

  'Heading': {cls: Heading, folder: "Helpers"},
  'Rating': {cls: Rating, folder: "Helpers"},
  'Search': {cls: Search, folder: "Helpers"},
  'SectionStyling': {cls: SectionStyling, folder: "Helpers"},
  'TableOfContent': {cls: TableOfContent, folder: "Helpers"},
  'Archive': {cls: Archive, folder: "Helpers"},

  'Intro': {cls: Intro, folder: "Sections", extraOptions: ["Hide TOC", "Add to TOC"]},
  'IntroLinks': {cls: IntroLinks, folder: "Sections"},
  'IntroReview': {cls: IntroReview, folder: "Sections", extraOptions: ["Hide TOC", "Add to TOC"]},
  'WriterMeta': {cls: WriterMeta, folder: "Sections"},
  'FixedData': {cls: FixedData, folder: "Sections"},

  'MatchCenter': {cls: MatchCenter, folder: "Sections"},
  'BettingTips': {cls: BettingTips, folder: "Sections"},

  'Banner': {cls: Banner, folder: "Testing"},
  'CricketBettingTipsSingle': {cls: CricketBettingTipsSingle, folder: "Testing"},
  'OddsList': {cls: OddsList, folder: "Testing"},
  'PredictionsList': {cls: PredictionsList, folder: "Testing"},
  'StepByStep': {cls: StepByStep, folder: "Testing"},
/*

  'ContactForm': {cls: ContactForm, folder: "Features"},
  'ReviewHero': {cls: ReviewHero, folder: "Features"},

  'About': {cls: About, folder: "Match"},
  'Dream11': {cls: Dream11, folder: "Match"},
  'Header': {cls: Header, folder: "Match"},
  'Info': {cls: Info, folder: "Match"},
  'Livescore': {cls: Livescore, folder: "Match"},
  'Odds': {cls: Odds, folder: "Match"},
  'Teampreviews': {cls: Teampreviews, folder: "Match"},

  'IntroTournament': {cls: IntroTournament, folder: "Tournament"},
  'LatestPrediction': {cls: LatestPredictions, folder: "Tournament"},
  'PointsTable': {cls: PointsTable, folder: "Tournament"},

  'ArticleEventHeader': {cls: ArticleEventHeader, folder: "Sections"},
  'ArticleMeta': {cls: ArticleMeta, folder: "Sections"},
  'Guide': {cls: Guide, folder: "Sections"},
  'IntroArticle': {cls: IntroArticle, folder: "Sections"},
  'IntroLinks': {cls: IntroLinks, folder: "Sections"},

  'Newsletter': {cls: Newsletter, folder: "Testing"},
  'ReviewTemplate': {cls: ReviewTemplate, folder: "Testing"},
*/

}

export default imports