<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from '$lib/admin/components/Input.svelte';

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group colspan="8-4" addClass="form-item">
    <Input label="YouTube video code (11 chars)" placeholder="Insert YouTube code" bind:value={props.videoCode} on:keyup />
  </Group>
  <Group colspan="2-10" addClass="form-item">
    <Input label="Caption" placeholder="Write a caption" bind:value={props.videoCaption} on:keyup />
  </Group>
{:else}
  {#if props.videoCode}
    <div class="block block-video">
      <div class="container">
        <div class="grid">
          <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
            <div class="video-frame">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/{props.videoCode}?controls=0" title={ props.videoCaption } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            {#if props.videoCaption}
              <p>{@html props.videoCaption}</p>
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}
{/if}

<style>
  .t-cf .block-video .grid > div {
    border-radius: var(--bd-rad);
    background: #f3f3f3;
    padding: 0.675rem;
  }
  .t-cf .block-video .grid .video-frame {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 56.25% 0;
    overflow: hidden;
  }
  .t-cf .block-video .grid .video-frame iframe {
    border-radius: var(--bd-rad-sm);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .t-cf .block-video .grid p {
    margin: 0.675rem 0 0.175rem 0;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .t-cf .block-video:hover .grid p {
    white-space: normal;
  }
</style>