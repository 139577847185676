<script>
  import { onMount } from "svelte";
  import suneditor from 'suneditor';
  import plugins from 'suneditor/src/plugins';

  export let text = null
  // export let placeholder = null
  let editor = null
  const id = Math.random().toString(16);

  onMount(async () => {
		const editor = suneditor.create(id, {
      plugins: plugins,
      buttonList: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'blockquote'],
        ['list', 'formatBlock'],
        ['removeFormat', 'showBlocks']
      ]
    })
    editor.setContents(text)
    editor.onChange = function (contents, core) { text=contents };
  });
</script>

<div class="editor-wrapper" on:keyup>
  <div {id} />
</div>

<style>
  @import 'suneditor/dist/css/suneditor.min.css';
</style>