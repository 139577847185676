<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import FocusPanel from '$lib/admin/components/blocks/blocks/FocusPanel.svelte';
  import Table from '$lib/admin/components/blocks/blocks/Table.svelte';

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group addClass="form-item">
    <Group colspan="4-8">
      <Input label="Facts data" type="select" bind:value={props.fixedDataType} on:change>
        <option value="fp-bss">Bonus Spins Spins - Focus Panel</option>
        <option value="fp-rrn">Reviews Reviews News - Focus Panel</option>
        <option value="table-rtp">Highest RTP slots - Table</option>
      </Input>
    </Group>
  </Group>
{:else}
  {#if props?.fixedDataType?.startsWith('fp-')}
    {#if props.fixedDataType.includes('bss')}
      <FocusPanel props={{
        "focusPanelType": "facts",
        "focusPanelFacts": [
          {
            "number": "70 500 kr",
            "description": "casinobonus"
          },
          {
            "number": "2 772",
            "description": "free spins"
          },
          {
            "number": "0",
            "description": "free spins utan innsättning"
          }
        ]
      }} />
    {:else if props.fixedDataType.includes('rrn')}
      {#if props.data}
        <FocusPanel props={{
          "focusPanelType": "facts",
          "focusPanelFacts": [
            {
              "number": "3 456",
              "description": "licensierade casinon"
            },
            {
              "number": "2 345",
              "description": "slots recenserade"
            },
            {
              "number": "1 234",
              "description": "casinonyheter publicerade"
            }
          ]
        }} />
      {/if}
    {/if}
  {:else if props?.fixedDataType?.startsWith('table-')}
    <Table props={{
      "tableColumns": 3,
      "tableHeaders": [
        "Slot",
        "Tillverkare",
        "RTP"
      ],
      "tableContent": [
        [
          "<a href='/slots/blood-suckers' title='Blood Suckers'>Blood Suckers</a>",
          "NetEnt",
          "98%"
        ],
        [
          "<a href='/slots/furballs' title='Furballs'>Furballs</a>",
          "Microgaming",
          "97.71%"
        ]
      ]
      }} />
  {/if}
{/if}