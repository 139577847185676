<script>
  import { project, postUsers } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;

  const dispatch = createEventDispatcher();

  $: userData = (Object.keys($postUsers).length > 0) ? $postUsers.find(x => x.id === props?.writerId) : null;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Choose writer"
      type="select"
      bind:value={props.writerId}
      on:change={() => dispatch("keyup")}>
      <option value="">None</option>
      {#if $postUsers}
        {#each $postUsers as user}
          <option value={user.id}>{user.full_name}</option>
        {/each}
      {/if}
    </Input>
  </Group>
{:else}
  <Block
    blockClass="block-writer-meta block-focus-panel"
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    <div class="focus-panel">
      <p class="writer-header"><Translate text="Content editor" /></p>
      <div class="writer-section">
        <div
          class="writer-profile-image"
          class:fallback={!userData?.avatar_url}>
          <img
            alt={userData?.full_name ?? ($project.name + " Team")}
            src={
              userData?.avatar_url
              ? $project.data?.imageFolders?.profiles + userData?.avatar_url.split("/").pop() + "?w=100&h=100&q=85&auto=format&fit=crop"
              : $project.data?.imageFolders?.assets + $project.name_lower + "-logo.png?q=85&auto=format"
            }
            loading="lazy">
        </div>
        <div class="writer-info">
          <p class="writer-name">{ userData?.display_name ?? ($project.name + " Team") }</p>
          <p class="writer-role">{ userData?.data?.[$project.key]?.role ?? "Group effort"}</p>
          <div class="writer-socials">
            <a
              class={ "_gmail" + (!userData?.data?.[$project.key]?.contact_email ? " disabled" : "") }
              href={ "mailto:" + userData?.data?.[$project.key]?.contact_email }
              target="_blank"
              rel="nofollow noreferrer"
              title={ "Maila " + userData?.full_name }>
              <Icons name="mail" color="#c71610" strokeWidth="2.5" />
            </a>
            <a
              class={ "_linkedin" + (!userData?.data?.[$project.key]?.linkedin ? " disabled" : "") }
              href={ userData?.data?.[$project.key]?.linkedin}
              target="_blank"
              rel="author noreferrer"
              title={ userData?.full_name + " på LinkedIn"}>
              <Icons name="linkedin" color="#fff" strokeWidth="1.5" />
            </a>
          </div>
        </div>
      </div>
      <p class="writer-description">{ userData?.data?.[$project.key]?.bio ?? "Content produced as a collaboration between our resident experts here at " + $project.name }</p>
    </div>
  </Block>
{/if}