<script>
  import { project, orgOperators, translations } from '$lib/services/store';
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Intro from "$lib/admin/components/blocks/sections/Intro.svelte";
  import Bonus from "$lib/admin/components/blocks/blocks/Bonus.svelte";
  import Rating from '$lib/admin/components/blocks/helpers/Rating.svelte';
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import ArticleAd from '$lib/admin/components/blocks/helpers/ArticleAd.svelte';
import FocusPanel from "$lib/admin/components/blocks/blocks/FocusPanel.svelte"
  import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
  import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;

	let selectedTab = "facts";
  let reviewOperator = null;

  const dispatch = createEventDispatcher();

  props.bonusType = "single-table";
  props.showCTA = true;

  $: {
    reviewOperator = $orgOperators?.find(x => x.id === props.operatorId)
    props.header = reviewOperator?.name
    props.buttonAnchor = $translations["To"][$project.settings.lang] + " " + reviewOperator?.name || "Casino Review"
    props.buttonUrl = "https://www.casinofever.ca/go/" + reviewOperator?.name_lower
    props.tocData = {
      "tag": "facts",
      "title": "Facts about " + reviewOperator?.name
    }
  }
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change={() => dispatch("keyup")}>
      <option value="">~not set~</option>
      {#each $orgOperators as operator}
        <option value={operator.id}>{operator.name || "Couldn't find operator name"}</option>
      {/each}
    </Input>
  </Group>
  <Group addClass="form-item">
    <SunEditorForm
      placeholder="Introduction"
      bind:text={props.content}
      on:keyup />
  </Group>
{:else}
  <Intro {props} {adminView} />
  <ArticleAd />
  {#if 2 < 1}
    <!--
      TODO:
      - figure out how determine when to show this section
      - translation?
      - how to fetch data? This is not a part of the content-loop. Maybe just increase fetching numbers of default toplist for this?
    -->
    <FocusPanel props={{
      "focusPanelType": "basic",
      "focusPanelContent": "<p>Detta casino har stängt eller är inte längre verksamma i Sverige. Kolla in vår <a href='/svenska-casinon#listan'>lista över svenska casinon</a> istället.</p>",
      "focusPanelBasicType": "warning"
    }} />
    <Toplist props={{
      "toplistId": 9,
      "headingSize": "h3",
      "headingText": "Casinot är stängt - Prova ett liknande casino",
      "toplistType": "table",
      "toplistFocus": "casino",
      "toplistLength": 5,
      "toplistLinkUrl": "/svenska-casinon",
      "toplistLinkAnchor": "Visa alla casinon"
    }} />
  {:else}
    <Bonus {props} {adminView} />
  {/if}
  <div id="facts" class="block block-tabbed-tables">
    <div class="container">
      <div class="grid">
        <div class="g-col-12">
          <div class="tab-section">
            <ul class="tabs">
              <li
                class={selectedTab === "facts" ? "active" : ""}>
                <button
                  on:click={() => {
                    selectedTab = "facts";
                  }}><Translate text="Facts about" /> {reviewOperator?.name}</button>
              </li>
              <li
                class={selectedTab === "info" ? "active" : ""}>
                <button
                  on:click={() => {
                    selectedTab = "info";
                  }}><Translate text="More info" /></button>
              </li>
            </ul>

            <table>
              <tbody>
                {#if selectedTab == "facts"}
                  <tr>
                    <td><Translate text="English license" lang={$project?.settings?.market} /></td>
                    <td>{reviewOperator?.countries.includes($project.settings.market) ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td><Translate text="Games offered" /></td>
                    <td>{reviewOperator?.data?.numberOfGames?.[$project.settings.market] ?? "-"}</td>
                  </tr>
                  <tr>
                    <td><Translate text="Number of games" /></td>
                    <td>{reviewOperator?.data?.numberOfGames?.[$project.settings.market] ?? "-"}</td>
                  </tr>
                  <tr>
                    <td><Translate text="Support" /></td>
                    <td>
                      {#if reviewOperator?.data?.support?.[$project.settings.market]?.length}
                        {#each reviewOperator.data.support[$project.settings.market] as support, i}
                          {support + ((reviewOperator.data.support[$project.settings.market].length > i + 1) ? ", " : "")}
                        {/each}
                      {:else}
                        -
                      {/if}
                  </td>
                  </tr>
                  <tr>
                    <td><Translate text="Tax free winnings" /></td>
                    <td>{reviewOperator?.data?.taxFreeWinnings ?? "-"}</td>
                  </tr>
                  <tr>
                    <td><Translate text="Our rating" /></td>
                    <td class="rating-cell"><Rating operator={reviewOperator} /></td>
                  </tr>
                {:else}
                  <tr>
                    <td><Translate text="License" /></td>
                    <td>
                      {#if reviewOperator?.licenses?.length}
                        {#each reviewOperator.licenses as license, i}
                          {license + ((reviewOperator.licenses.length > i + 1) ? ", " : "")}
                        {/each}
                      {:else}
                        None
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td><Translate text="Founded" /></td>
                    <td>{reviewOperator?.founded ?? "-"}</td>
                  </tr>
                  <tr>
                    <td><Translate text="Game providers" /></td>
                    <td>
                      {#if reviewOperator?.data?.gameProviders?.[$project.settings.market]}
                        {#each reviewOperator.data.gameProviders?.[$project.settings.market] as gameProvider, i}
                          {#if props.gameProviderLinks?.hasOwnProperty(gameProvider)}
                            <a href={props.gameProviderLinks?.[gameProvider]}>{gameProvider + ((reviewOperator.data.gameProviders?.[$project.settings.market].length > i + 1) ? ", " : "")}</a>
                          {:else}
                            {gameProvider + ((reviewOperator.data.gameProviders?.[$project.settings.market].length > i + 1) ? ", " : "")}
                          {/if}
                        {/each}
                      {:else}
                        None
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td><Translate text="Mobile casino" /></td>
                    <td>{reviewOperator?.data?.mobileCasino ?? "-"}</td>
                  </tr>
                {/if}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}