<script>
  import PreviewContent from "$lib/admin/components/content/PreviewContent.svelte";
  import {
    projectToplists,
    orgOperators,
    orgCasinoGames,
    orgGameProviders,
    postData,
    postUsers
  } from "$lib/services/store";

  export let data;

  let { match, post, session } = data;
  $: ({ match, post, session } = data);


  // Reactively update the data stores. This any store that needs to
  // be updated on route change should be added here
  $: {
    postUsers.set(data.usersData);
    postData.set(data.post);
    projectToplists.set(data.toplistData);
    orgOperators.set(data.operatorData);
    orgCasinoGames.set(data.casinoGamesData);
    orgGameProviders.set(data.gameProvidersData);
  }
</script>

<svelte:head>
  <title
    >{post.seo.page_title
      ? post.seo.page_title
      : "No page title set - " + post.project_key.toUpperCase()}</title
  >
  <meta
    name="description"
    content={post.seo.meta_description
      ? post.seo.meta_description
      : "No meta description set"}
  />
</svelte:head>

<PreviewContent {post} {session} />