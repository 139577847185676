<script>
	import { fly } from 'svelte/transition';
	import { page } from "$app/stores";
  import { project, previewMode, previewFullHeightMode } from "$lib/services/store";
  import Placeholder from "$lib/admin/components/blocks/helpers/Placeholder.svelte";
  import Nav from "$lib/main/Nav.svelte";
  import Footer from "$lib/main/Footer.svelte";
  import Sidebar from "$lib/main/Sidebar.svelte";
  import Breadcrumbs from "$lib/main/components/Breadcrumbs.svelte";
  import AdminButton from "$lib/admin/components/login/AdminButton.svelte";
  import Icons from "$lib/main/components/Icons.svelte";

  import imports from "$lib/admin/components/blocks/Importer.js";
  import { afterUpdate } from "svelte";

  export let post = null;
  export let docMode = false;
  export let session = null;

  const findCard = (index) => {
    const cards = document.querySelectorAll('.create > .card')
    return cards[index];
  }

  function snapScroll(event, index) {
    window.scrollTo(0, 0)
    const rightWindow = document.getElementsByClassName('window')[0];
    const leftWindow = document.getElementsByClassName('create')[0];
    const rightPos = event.currentTarget.offsetTop;

    const leftCard = findCard(index)
    leftCard.classList.add('highlight-column');
    const leftPos = leftCard.offsetTop - 150;

    leftWindow.scrollTop = leftPos
    rightWindow.scrollTop = rightPos;
  }

  let hoverIndex;
  let hoverOffsetTop

  afterUpdate(() => {
    if ($previewMode === true) {
      const allBlocks = document.querySelectorAll(".block");

      allBlocks.forEach((item, index) => {
        item.addEventListener("mouseenter", function (e) {
          hoverIndex = index
          hoverOffsetTop = e.target.offsetTop
        })
      })
    }
  })
</script>

{#if $previewMode}
  <Nav project={$project} />
  <main class:sidebar={$project.settings?.sidebar}>
    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
    <div
      id="preview-window"
      class="single-page"
      class:sidebar={$project.settings?.sidebar}
      class:full-height={$previewFullHeightMode === true}>
      <Breadcrumbs path={'/' + (docMode ? 'example' : post.slug)} />

      {#if post}
        {#each post.content as block, index}
          {#if imports[block.blockType]?.cls}
            <svelte:component
              this={imports[block.blockType].cls}
              bind:props={block.props}
              adminView={false} />
          {:else}
            <svelte:component
              this={ Placeholder }
              props={ { content: "Could not find or render this component.." } } />
          {/if}
          {#if index == hoverIndex - 2}
            <button
              in:fly="{{ x: -300, duration: 400 }}"
              out:fly="{{ x: -300, duration: 400 }}"
              class="edit-button"
              title="View the below block"
              style={ "top: calc(" + hoverOffsetTop + "px + 0.5rem)" } on:click={(event) => snapScroll(event, index) }><Icons strokeWidth="2" name="columns" />
            </button>
          {/if}
        {/each}
      {/if}
    </div>

    {#if $project.settings?.sidebar}
      <Sidebar />
    {/if}
  </main>

  <Footer {$project} type={ $project.settings.footerType } />
{:else}
  <div class="single-page" class:sidebar={$project.settings?.sidebar}>
    <Breadcrumbs path={$page.url.pathname} />

    {#if post?.content}
      {#each post.content as block}
        {#if imports[block.blockType]?.cls}
          <svelte:component
            this={imports[block.blockType].cls}
            bind:props={block.props}
            adminView={false} />
        {/if}
      {/each}
    {/if}
  </div>

  <AdminButton {session} {post} />
{/if}