<script>
  import { postData } from "$lib/services/store";
  import { dateFormatter } from "$lib/services/utils";
  import Group from "$lib/admin/components/Group.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group>
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label>Article last updated: {$postData?.updated_at}</label>
  </Group>
{:else}
  <Block blockClass="block-last-updated" {props}>
    <div class="content">
      <span><Translate text="Last updated" />:</span>
      <span class="date">{dateFormatter($postData?.updated_at, "se")}</span>
    </div>
  </Block>
{/if}