<script>
  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <p>AdminView for StepByStep.svelte</p>
{:else}
  {#if props}
    <p>StepByStep - without `props`</p>
  {:else}
    <p>StepByStep - actual</p>
  {/if}
{/if}