<script>
  import { project, orgOperators } from '$lib/services/store';
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;

  let selectedTab = "casino";

  const dispatch = createEventDispatcher();
</script>

{#if adminView}
  <Group colspan="2" addClass="form-item">
    <Group>
      <Input
        label="Type"
        type="select"
        bind:value={props.bonusType}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        <option value="bonus">Bonus</option>
        <option value="small">Small</option>
        <option value="review">Review</option>
        <option value="single-table">Single table</option>
        <option value="bonus-boxes">Bonus boxes</option>
      </Input>
    </Group>
    <Group>
      <Input
        label="Operator"
        type="select"
        bind:value={props.operatorId}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        {#each $orgOperators as operator}
          <option value={operator.id}>{operator.name || "Couldn't find metadata"}</option>
        {/each}
      </Input>
    </Group>
  </Group>
  {#if props.bonusType != "single-table"}
    <Group addClass="form-item">
      <SunEditorForm bind:text={props.content} placeholder="Review text" on:keyup />
    </Group>
  {/if}
{:else}
  {#if props.bonusType == 'bonus'}
    <div class="row">
      <div class="brand bonus">
        <a class={"logo " + props.metadata?.nameClass} href="/betting-sites/{props.metadata?.nameLower}">
          <img
            src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=85&auto=format"}
            data-src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=85&auto=format"}
            alt={props.metadata?.name}
            class="lazyload"
            loading="lazy">
        </a>
        <div class="offer">
          <h4>Betting Offer</h4>
          {#if props.content}
            <p>{@html props.content}</p>
          {:else}
            <p>Bet ₹1,000 and Get a ₹3,000 Free Bet</p>
          {/if}
        </div>
        <a class="visit" href={"/go/" + props.metadata?.nameLower} target="_blank" rel="nofollow noreferrer">Claim</a>
      </div>
    </div>
  {:else if props.bonusType == 'review'}
    <div class="row">
      <div class={"brand review " + props.metadata?.nameClass}>
        <img
          src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=85&auto=format"}
          data-src={$project.data?.imageFolders.operators + props.metadata?.nameLower + "-logo.png?w=300&q=85&auto=format"}
          alt={props.metadata?.name}
          class="lazyload"
          loading="lazy">
        <div class="info">
          {#if props.content}
            {@html props.content}
          {/if}
        </div>
        <a href={"/go/" + props.metadata?.nameLower}>Besøg {props.metadata?.name}</a>
        <a href={"/" + $project.data.categoryPages?.reviews?.slug + "/" + props.metadata?.nameLower}>Læs anmeldelse</a>
      </div>
    </div>
  {:else if props.bonusType == "single-table"}
    <Toplist props={ {
      "toplistOperatorId": props.operatorId,
      "toplistType": "table",
      "toplistFocus": "casino",
      "toplistLength": "1" } }/>
  {:else if props.bonusType == "bonus-boxes"}
    {#if props.operatorId}
      {@const operator = $orgOperators.find(x => x.id == props?.operatorId)}
      <Block
        blockClass="block-tabbed-tables bonus-boxes"
        gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
        {props}>
        <div class="tab-section">
          <ul class="tabs">
            {#if operator.bonus.casino[$project.settings.market]}
              <li
                class:active={selectedTab == "casino"}>
                <button
                  on:click={() => {
                    selectedTab = "casino";
                  }}><Translate text="Casino bonus" /></button>
              </li>
            {/if}
            {#if operator.bonus.sports[$project.settings.market] && operator.bonus.sports[$project.settings.market].amount}
              <li
                class:active={selectedTab == "sports"}>
                <button
                  on:click={() => {
                    selectedTab = "sports";
                  }}><Translate text="Sports bonus" /></button>
              </li>
            {/if}
          </ul>

          <table>
            <tbody>
              {#if selectedTab == "casino"}
                <tr>
                  <td><Translate text="Casino bonus" /></td>
                  <td>{operator.bonus.casino[$project.settings.market].bonus}</td>
                </tr>
                <tr>
                  <td><Translate text="Wagering req." /> bonus</td>
                  <td>{operator.bonus.casino[$project.settings.market].turnover} x</td>
                </tr>
              {:else if selectedTab == "sports"}
                <tr>
                  <td><Translate text="Sports bonus" /></td>
                  <td>{operator.bonus.sports[$project.settings.market].bonus}</td>
                </tr>
                <tr>
                  <td><Translate text="Wagering req." /> bonus</td>
                  <td>{operator.bonus.sports[$project.settings.market].turnover}</td>
                </tr>
                <tr>
                  <td><Translate text="Min. odds" /></td>
                  <td>{operator.bonus.sports[$project.settings.market].min_odds}</td>
                </tr>
              {/if}
            </tbody>
          </table>

          {#if selectedTab == "casino"}
            <a href={"/go/" + operator.name_lower}>
              <span><Translate text="To" /> {operator.name}</span>
              <span>Casino</span>
            </a>
            <i>{@html operator.bonus.casino[$project.settings.market].tc}</i>
          {:else}
            <a href={"/go/" + operator.name_lower}>
              <span><Translate text="To" /> {operator.name}</span>
              <span>Sport</span>
            </a>
            <i>{@html operator.bonus.sports[$project.settings.market].tc}</i>
          {/if}
        </div>
      </Block>
    {/if}
  {:else}
    <p>Not found</p>
  {/if}
{/if}

<style>
  .row {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .brand.bonus {
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    width: 100%;
    margin: 0 auto 1.5em auto;
    max-width: 400px;
  }
  a.logo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 75px;
    max-width: 100%;
  }
  .offer {
    padding: 30px 15px 20px 15px;
    width: 100%;
    text-align: center;
  }
  .offer h4 {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 0 0.5em 0;
    color: #f49c24;
  }
  .offer p {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    color: #222;
  }
  a.visit {
    display: inline-block;
    width: 180px;
    background: #1ba66b;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    border-radius: 2px;
    text-align: center;
    margin: 0 15px 15px 15px;
    padding: 15px;
    align-self: center;
  }

  .review {
    padding: 0.5rem;
    margin: 0 0 1rem 0;
    gap: 0.5rem;
    display: grid;
    grid-template-areas:
      "logo info"
      "visit review";
  }
  .review img {
    grid-area: logo;
    display: block;
    width: 100%;
    object-fit: contain;
    align-self: center;
  }
  .review .info {
    grid-area: info;
    align-self: flex-end;
  }
  :global(.review .info ul) {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 0.5rem;
  }
  :global(.review .info ul li) {
    position: relative;
    padding: 0 0 0 1.5rem;
    font-size: 0.8rem;
  }
  :global(.review .info ul li:before) {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    background: #1f9002;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 3px 0 0 0;
  }
  .review a:first-of-type {
    grid-area: visit;
    background: #125203;
    color: #fff;
    text-transform: uppercase;
  }
  .review a:last-of-type {
    grid-area: review;
  }
  .review a {
    padding: .5rem;
    background: #eee;
    color: #222;
    font-weight: 500;
    font-family: var(--ff-title);
    text-align: center;
  }
  .review a:first-of-type:hover {
    background: #002c00;
  }
  .review a:hover {
    background: #0b4066;
    color: #fff;
  }
</style>