// Date converting
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
]
const monthsTranslations = [
  ["january", "januari"],
  ["february", "februari"],
  ["march", "mars"],
  ["april"],
  ["may","maj"],
  ["june", "juni"],
  ["july", "juli"],
  ["august", "augusti"],
  ["september"],
  ["october", "oktober"],
  ["november"],
  ["december"]
]

export function dateFormatter(date, region = "en") {
  const d = new Date(date)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]

  let day = d.getDate()
  let month = months[d.getMonth()]
  let year = d.getFullYear()

  if (region == "se") {
    return day + " " + month + " " + year
  }
  if (region == "en") {
    return month + " " + day + " " + year
  }
  return month + " " + day + " " + year
}

export function monthNumberToMonthLetters(monthNumber, capitalize = false) {
  const monthsCapitalized = months.map(m => m.charAt(0).toUpperCase() + m.slice(1))
  if(capitalize) return monthsCapitalized[monthNumber - 1]
  return months[monthNumber - 1]
}

export function monthLettersToMonthNumber (monthLetters) {
  let index = monthsTranslations.findIndex(x => x.includes(monthLetters.toLowerCase()))
  return index + 1
}

// Removes non-alphanumeric characters and makes it all lowercase
// NOT CURRENTLY USED (remove this line if this is actually used anymore)
export function nameToLower(name) {
  let nameLower = ''
  for (var i = 0; i < name.length; i++) {
    if (!name[i].match(/[a-z]/i) && !name[i].match(/[0-9]/i)) {
      nameLower += '-'
    } else {
      nameLower += name[i]
    }
  }
  return nameLower.toLowerCase()
}

// Parses datetime fetched from Supabase
export function parseDatetime(datetime) {
  if (!datetime) {
    return ""
  }
  return `${datetime.split(':')[0]}:${datetime.split(':')[1]}`
}

// compare two arrays
export function arraysSameContent(array1, array2) {
  if (!array1 || !array2) {
    return false
  }
  return array1.length === array2.length && array1.every((element, index) => element === array2[index])
}